const setButtonLink = () => {
  const urlParams = new URLSearchParams(window.location.search);

  let ref = urlParams.get('ref');

  if (!ref) return;

  try {
    ref = new URL(ref).searchParams.get('start');
  } catch (e) {}

  const button = document.getElementById('button');
  const buttonLinkURL = new URL(button.href);
  buttonLinkURL.searchParams.set('start', ref);
  button.href = buttonLinkURL.toString();
  button.dataset.ganame = ref;
  button.dataset.botref = ref;
}

setButtonLink();